import AddToCartArgs from "../AddToCartArgs";
import AddToCartBulkArgs from "../AddToCartBulkArgs";
import ProductViewModel from "./ProductViewModel";
import Package from "@/chipply/Package";
import ProductOption from "../ProductOption";
import IProcessOptionItem from "../IProcessOptionItem";
import { WebHelper } from "chipply-common";
import ParentProductViewModel from "./ParentProductViewModel";
import IPackageDto from "../i-package-dto";
import IGetPackageResults from "../IGetPackageResults";
import GetPackageInfoArgs from "../GetPackageInfoArgs";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import PackageDetailPage from "@/pages/package-detail-dev";

export default class PackageViewModel {
    public packageItems: ProductViewModel[] = [];
    public packageParents: ParentProductViewModel[] = [];
    public package!: Package;
    public packageInitialized = false;
    public isRequestor = false;
    public eventId!: number;
    public hideIndividualPrices = false;
    public loading = false;
    public usedExclusives: { [key: string]: IProcessOptionItem[] } = {};
    public pureOptions: IProcessOptionModel[] = [];

    public async setPackage(pkg: Package) {
        this.packageInitialized = false;
        this.package = pkg;

        await this.getPackageProducts();
    }

    public async getPackageProducts() {
        const args = new GetPackageInfoArgs();
        args.categoryId = this.package.eventCategoryID;
        this.package.products.products.forEach((item) => {
            args.targetProducts.push(item.eventProductID);
        });

        const packageResult = (await WebHelper.postJson<IGetPackageResults>(
            `/api/EventProductEcom/GetPackageResult`,
            args
        )) as IGetPackageResults;
        this.hideIndividualPrices = packageResult.hideIndividualPrices;

        packageResult.productResults.forEach(async (item) => {
            const parentVm = new ParentProductViewModel();
            parentVm.parent = new ProductViewModel();
            parentVm.parent.fromModel(item);
            parentVm.parent.categoryId = packageResult.categoryId;
            parentVm.selectedVm = parentVm.parent;

            // parentVm.selectedVm.options.forEach((option) => {
            //     if(option.processType == 5){
            //         this.usedExclusives[option.optionId] = [];
            //         option.options.forEach((choice) => {
            //             this.usedExclusives[option.optionId].push({display: choice.display, optionItemId: choice.optionItemId, isUsed: choice.isUsed});
            //         })
            //     }
            // })

            if (!this.isRequestor) {
                await parentVm.selectedVm.setInitialColorSize();
            }
            parentVm.parentInitialized = true;
            if (parentVm.selectedVm.product?.enabled == 1) {
                this.packageParents.push(parentVm);
            }
        });

        this.packageInitialized = true;
    }

    public getCompletedNumber() {
        let completed = 0;
        this.packageParents.forEach((item) => {
            if (item.selectedVm.pkgItemFinalized) {
                completed++;
            }
        });
        return completed;
    }

    public async checkParentInitializations() {
        let count = 0;
        this.packageParents.forEach((parentPkg) => {
            if (parentPkg.parentInitialized) {
                count++;
            }
        });

        if (count == this.packageParents.length) {
            return true;
        } else {
            return false;
        }
    }

    public async getPackageItem(pkgId: number) {
        let targetVm: ProductViewModel | null = null;
        this.packageItems.forEach((pkg) => {
            if (pkg.productId == pkgId) {
                targetVm = pkg;
            }
        });
        return targetVm;
    }

    public async getPackageParentItem(pkgId: number) {
        let targetParentVm: ParentProductViewModel = new ParentProductViewModel();
        this.packageParents.forEach((pkg) => {
            if (pkg.selectedVm.productId == pkgId) {
                targetParentVm = pkg;
            }
        });
        return targetParentVm;
    }

    public async addRequestorPackageToCart() {
        const bulkModel = new AddToCartBulkArgs();
        let packageId = 0;

        if (this.getCompletedNumber() == this.packageParents.length) {
            this.packageParents.forEach((item) => {
                const args = new AddToCartArgs();
                if (item.selectedVm.product) {
                    args.productId = item.selectedVm.product.eventProductID;
                    args.sizeId = item.selectedVm.selectedSize;
                    args.packageId = item.selectedVm.categoryId;
                    args.colorId = item.selectedVm.selectedReqColors[0].eventProductColorId;
                    packageId = args.packageId as number;

                    //for requestor always assume 1 qty
                    const qty = 1;
                    args.quantity = qty;
                    args.eventId = item.selectedVm.product.eventID;

                    let firstColor = false;

                    //add the colors
                    item.selectedVm.selectedReqColors.forEach((color, index) => {
                        if (color.selected) {
                            const po = new ProductOption();
                            po.text = color.colorName + " - " + color.hexValue;
                            po.processItemId = 0;
                            po.processOptionId = 0;
                            po.processName = "Color";
                            args.productOptions.push(po);

                            if (!firstColor) {
                                args.colorId = color.eventProductColorId;
                                firstColor = true;
                            }
                        }
                    });

                    //add layout if present
                    if (item.selectedVm.selectedLayout != "") {
                        const poLayout = new ProductOption();
                        poLayout.text = item.selectedVm.selectedLayout;
                        poLayout.processItemId = 0;
                        poLayout.processOptionId = 0;
                        poLayout.processName = "Layout";
                        args.productOptions.push(poLayout);
                    }

                    // if (this.layoutName != "" && this.selectedLayout == "Create New Layout") {
                    //     const poLayout = new ProductOption();
                    //     poLayout.text = this.layoutName;
                    //     this.value.selectedLayout = this.layoutName;
                    //     poLayout.processItemId = 0;
                    //     poLayout.processOptionId = 0;
                    //     poLayout.processName = "Layout";
                    //     args.productOptions.push(poLayout);
                    // } else if (this.selectedLayout != "") {
                    //     const poLayout = new ProductOption();
                    //     poLayout.text = this.selectedLayout;
                    //     this.value.selectedLayout = this.selectedLayout;
                    //     poLayout.processItemId = 0;
                    //     poLayout.processOptionId = 0;
                    //     poLayout.processName = "Layout";
                    //     args.productOptions.push(poLayout);
                    // }

                    if (!firstColor) {
                        return;
                    }

                    bulkModel.items.push(args);
                    bulkModel.packageId = packageId;
                }
            });

            await WebHelper.postJsonData("/api/EventProductEcom/AddPackageToCart", bulkModel);
            window.location.href = "./cart.aspx?eventId=" + this.eventId;
        }
    }

    public async addPackageToCart() {
        const bulkModel = new AddToCartBulkArgs();
        let packageId = 0;

        if (this.getCompletedNumber() == this.packageParents.length) {
            this.packageParents.forEach((item) => {
                const arg = new AddToCartArgs();
                if (item.selectedVm.product) {
                    arg.productId = item.selectedVm.product.eventProductID;
                    arg.sizeId = item.selectedVm.selectedSize;
                    arg.colorId = item.selectedVm.selectedColor.eventProductColorId;
                    arg.quantity = item.selectedVm.productQuantity;
                    arg.eventId = item.selectedVm.product.eventID;
                    arg.packageId = item.selectedVm.categoryId;
                    packageId = arg.packageId as number;
                    //packageNum should be used on the backend not frontend

                    item.selectedVm.options.forEach((attribute) => {
                        if (attribute.userValue) {
                            const po = new ProductOption();
                            if (attribute.processType == 4 || attribute.processType == 5) {
                                attribute.options.forEach((choice: IProcessOptionItem) => {
                                    if (choice.display == attribute.userValue) {
                                        po.processOptionId = choice.optionItemId;
                                    }
                                });
                            }
                            po.text = attribute.userValue;
                            po.processItemId = attribute.optionId;
                            arg.productOptions.push(po);
                        }
                    });

                    bulkModel.items.push(arg);
                    bulkModel.packageId = packageId;
                }
            });

            this.loading = true;
            await WebHelper.postJsonData("/api/EventProductEcom/AddPackageToCart", bulkModel);
            window.location.href = "./cart.aspx?eventId=" + this.eventId;
        }
    }
}
