













































































































































































































































































































































































































import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import EcomLayout from "../EcomLayout.vue";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import IPackageDto from "@/chipply/i-package-dto";
import IProductsDto from "@/chipply/i-products-dto";
import { typeDependencies, Serializer } from "chipply-common";
import { ProductsDto } from "@/chipply/products-dto";
import Package from "@/chipply/Package";
import Category from "@/chipply/event-category-dto";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import ProductInfoSelector from "../ProductInfoSelector.vue";
import ParentProductViewModel from "@/chipply/view-model/ParentProductViewModel";
import PackageViewModel from "@/chipply/view-model/PackageViewModel";
import ProductImageViewer from "../ProductImageViewer.vue";
import RequestorInfoSelector from "../RequestorInfoSelector.vue";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import PackageDrawerProductInfo from "@/components/design-elements/PackageDrawerProductInfo.vue";
import { WebHelper } from "chipply-common";
import { number } from "card-validator";

@Component({
    components: {
        PackageDrawerProductInfo,
        EcomLayout,
        ProductInfoSelector,
        ProductImageViewer,
        RequestorInfoSelector,
    },
})
@typeDependencies({
    types: {
        ProductsDto,
        Package,
        Category,
    },
})
export default class PackageDetailPage extends Vue {
    public name = "PackageDetailPage";
    public cartVm: CartViewModel = new CartViewModel();
    public categoryId = 0;
    public eventId = 0;
    private crumbs: Array<any> = [];
    public packageName = "";
    public parentCat = "";
    public myPackage = new Package();
    private drawer = false;
    public selectedProduct: ProductViewModel = new ProductViewModel();
    public currentClonedParent!: ParentProductViewModel;
    public packageVm: PackageViewModel = new PackageViewModel();
    public color = 0;
    public pkgInitialized = false;
    public loading = false;
    public lastTargetedItem!: ParentProductViewModel;
    public isPreview = false;
    public packageTotalPrice: number | null = 0;

    private requestorLayouts: Array<any> = new Array<any>();

    public get returnToStoreUrl() {
        let storeId = this.packageVm.eventId;
        let baseUrl = `./store.aspx?eid=${storeId}&acid=${this.categoryId}`;
        return baseUrl;
    }

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("cid")) {
            this.categoryId = Number(urlParams.get("cid"));
        }

        if (urlParams.has("eid")) {
            this.eventId = Number(urlParams.get("eid"));
        }

        if (this.eventId != 0) {
            await this.cartVm.get(this.eventId);
            await this.cartVm.getCartItems(this.eventId);
            await this.cartVm.getCategories(this.eventId, true);
            if (this.cartVm.isRequestor) {
                this.cartVm.loModel.layouts.forEach((lo) => {
                    this.requestorLayouts.push(lo);
                });
            }

            this.cartVm.initialized = true;
        }

        this.cartVm.categories.containers.forEach((cat) => {
            if (cat.eventCategoryID == this.categoryId && cat.isPackage) {
                this.packageName = cat.name;
                var childCat: { text: string; disabled: boolean; href: string } = {
                    text: cat.name,
                    disabled: false,
                    href: `store.aspx?eid=${this.eventId}&acid=` + cat.eventCategoryID,
                };
                this.myPackage = cat as Package;
                this.crumbs.push(childCat);
            } else {
                if (!cat.isPackage) {
                    (cat as Category).containers.forEach((subCat) => {
                        if (subCat.eventCategoryID == this.categoryId && subCat.isPackage) {
                            this.packageName = subCat.name;
                            this.parentCat = cat.name;
                            var parentCat: { text: string; disabled: boolean; href: string } = {
                                text: cat.name,
                                disabled: true,
                                href: `store.aspx?eid=${this.eventId}&acid=` + cat.eventCategoryID,
                            };
                            this.crumbs.push(parentCat);
                            var childCat: { text: string; disabled: boolean; href: string } = {
                                text: subCat.name,
                                disabled: false,
                                href: `store.aspx?eid=${this.eventId}&acid=` + subCat.eventCategoryID,
                            };
                            this.crumbs.push(childCat);
                            this.myPackage = subCat as Package;
                        }
                    });
                }
            }
        });

        this.packageVm.setPackage(this.myPackage);
        this.packageVm.isRequestor = this.cartVm.isRequestor;
        if (this.cartVm.eventId) {
            this.packageVm.eventId = this.cartVm.eventId;
        }

        this.packageTotalPrice = this.packageVm.package.price;
    }

    public addPackageToCart() {
        if (!this.cartVm.isRequestor) {
            this.packageVm.addPackageToCart();
        } else {
            this.packageVm.addRequestorPackageToCart();
        }
    }

    private async editPackage(pkgId: number) {
        this.pkgInitialized = false;
        let targetParent = await this.packageVm.getPackageParentItem(pkgId);
        if (targetParent) {
            this.lastTargetedItem = targetParent;
            this.currentClonedParent = targetParent.clone();
            this.selectedProduct = this.currentClonedParent.selectedVm;
            const designList = this.selectedProduct.colorSize.designsObject.designs;
            await this.prepExclusives(this.selectedProduct);

            this.pkgInitialized = true;
            if (!this.drawer) {
                this.drawer = !this.drawer;
            }
        }
    }

    private maxQuantityReached() {
        let qtyReached = false;
        let designIds: any[] = [];
        let runningTotal = 0;

        //if there are multiple designs, we have to check the quantity of each design in the cart
        this.selectedProduct.colorSize.designsObject.designs.forEach((design: any) => {
            designIds.push(design.value);
        });

        this.cartVm.contents.forEach((item) => {
            if (designIds.includes(item.eventProductId)) {
                if (item.packageId == this.selectedProduct.categoryId) {
                    runningTotal += item.itemQuantity;
                }
            }
        });

        if (runningTotal == this.selectedProduct.product!.maxQuantity && !this.myPackage.allowMultiple) {
            qtyReached = true;
        }

        return qtyReached;
    }

    private async setSelectedValues() {
        this.lastTargetedItem.selectedVm = this.selectedProduct;
        if (this.cartVm.isRequestor) {
            await this.addLayout();
        }
        await this.handleExclusive();
        await this.updatePackageTotal();
    }

    private async addLayout() {
        var found = false;
        var selectedLayout = this.selectedProduct.selectedLayout;
        this.requestorLayouts.forEach((layout) => {
            if (layout.text == selectedLayout) {
                found = true;
            }
        });

        if (!found) {
            this.requestorLayouts.push({
                id: this.requestorLayouts.length + 1,
                text: selectedLayout,
            });
        }
    }

    private async resetExclusiveOptions() {
        if (this.selectedProduct.options.length == 0) {
            return;
        }

        this.selectedProduct.options.forEach((option) => {
            if (option.userValue != undefined) {
                if (option.processType != 5) {
                    return;
                }

                option.options.forEach((choice) => {});
            }
        });
    }

    private async handleExclusive() {
        if (this.selectedProduct.options.length > 0) {
            this.selectedProduct.options.forEach((option) => {
                if (option.userValue != undefined) {
                    if (option.processType == 5) {
                        option.options.forEach((choice) => {
                            if (choice.display == option.userValue) {
                                if (this.packageVm.usedExclusives[option.optionId]) {
                                    this.packageVm.usedExclusives[option.optionId].forEach((entry) => {
                                        if (entry.optionItemId == choice.optionItemId) {
                                            entry.isUsed = true;
                                        }
                                    });
                                }
                            }
                        });

                        if (option.userValue != option.originalValue && option.originalValue) {
                            this.packageVm.usedExclusives[option.optionId].forEach((entry) => {
                                if (entry.display == option.originalValue) {
                                    entry.isUsed = false;
                                }
                            });
                        }
                    }
                } else {
                    if (option.processType == 5 && option.originalValue) {
                        this.packageVm.usedExclusives[option.optionId].forEach((entry) => {
                            if (entry.display == option.originalValue) {
                                entry.isUsed = false;
                            }
                        });
                    }
                }
            });
        }
    }

    private async updatePackageTotal() {
        const ecomCartItems = [];
        const allItemsFinalized = this.packageVm.packageParents.every((parent) => parent.selectedVm.pkgItemFinalized);
        if (!allItemsFinalized) {
            return;
        }
        for (const parent of this.packageVm.packageParents) {
            const selectedVm = parent.selectedVm;
            if (selectedVm.pkgItemFinalized) {
                const ecomCartItem = {
                    eventProductID: selectedVm.product!.eventProductID,
                    itemSizeID: selectedVm.selectedSize,
                    itemQty: selectedVm.productQuantity,
                    ecomCartItemAttributes: selectedVm.options.map((option) => {
                        const attribute: any = {
                            EventProcessItemID: option.optionId,
                            UserValue: option.userValue || "",
                            EventProcessOptionID: 0,
                        };
                        if (option.processType === 4 || option.processType === 5) {
                            const selectedOption = option.options.find((o) => o.display === option.userValue);
                            if (selectedOption) {
                                attribute.EventProcessOptionID = selectedOption.optionItemId;
                            }
                        }
                        return attribute;
                    }),
                };
                ecomCartItems.push(ecomCartItem);
            }
        }
        if (ecomCartItems.length === 0) {
            return;
        }
        try {
            const response = await WebHelper.postJsonData("/api/EventProductEcom/UpdatePackageTotal", ecomCartItems);
            this.packageTotalPrice = Number(response);
        } catch (error) {
            console.error("Error updating package price:", error);
        }
    }

    private async setColor(e: number) {
        if (e != undefined) {
            this.color = e;
        }
    }

    private getButtonColor() {
        if (this.packageVm.getCompletedNumber() < this.packageVm.packageParents.length) {
            return "#909093";
        } else {
            return "#056DB9";
        }
    }

    private trimExclusives(option: IProcessOptionModel) {
        let pureCopy = Serializer.copy(this.packageVm.pureOptions);
        pureCopy.forEach((copiedOption) => {
            if (copiedOption.optionId == option.optionId) {
                option.options = copiedOption.options;
            }
        });
        let indexToRemove = -1;
        const usedExclusives = this.packageVm.usedExclusives;
        option.options.forEach((choice) => {
            if (usedExclusives[option.optionId]) {
                usedExclusives[option.optionId].forEach((used) => {
                    if (choice.optionItemId == used.optionItemId && used.isUsed && choice.display != option.userValue) {
                        indexToRemove = option.options.indexOf(choice);
                    }
                });
            }
        });

        if (indexToRemove != -1) {
            option.options.splice(indexToRemove, 1);
        }
    }

    private async prepExclusives(viewModel: ProductViewModel) {
        if (this.packageVm.pureOptions.length == 0) {
            this.packageVm.pureOptions = Serializer.copy(viewModel.options);
        }
        viewModel.options.forEach((option) => {
            if (option.processType == 5) {
                const usedExclusives = this.packageVm.usedExclusives;
                if (usedExclusives[option.optionId] == null) {
                    usedExclusives[option.optionId] = [];
                    option.options.forEach((choice) => {
                        usedExclusives[option.optionId].push({
                            display: choice.display,
                            optionItemId: choice.optionItemId,
                            isUsed: false,
                        });
                    });
                } else {
                    this.trimExclusives(option);
                }
            }
        });
    }

    private async changeDesign(e: number) {
        var tempColor = this.selectedProduct.selectedColor;
        var tempSize = this.selectedProduct.selectedSize;
        var tempQuantity = this.selectedProduct.productQuantity;
        var tempOptions: IProcessOptionModel[] = [];
        var tempSizeDisplay = "";
        this.selectedProduct.colorSize.sizes?.forEach((size) => {
            if (size.value == tempSize) {
                tempSizeDisplay = size.display;
            }
        });
        this.selectedProduct.loading = true;
        var child: ProductViewModel = await this.currentClonedParent.getChild(e, this.categoryId);
        if (child.product) {
            var parentTest = await this.packageVm.getPackageParentItem(child.product.eventProductID);

            if (parentTest.selectedVm.options.length > 0) {
                tempOptions = parentTest.selectedVm.options;
            }
        }
        this.selectedProduct.loading = false;
        child.colorSize.designsObject.designs.forEach((button: any) => {
            if (button.value == e) {
                button.selected = true;
            } else {
                button.selected = false;
            }
        });
        this.selectedProduct = child;
        let tempId = 0;
        child.colorSize.colors?.forEach((color) => {
            if (color.colorName === tempColor.colorName) {
                tempId = color.eventProductColorId;
            }
        });
        await this.selectedProduct.rebuildColorsAndSizes(tempId);
        this.selectedProduct.productColorCollection.forEach((color) => {
            if (color.colorName === tempColor.colorName) {
                child.selectedColor = color;
            }
        });
        child.colorSize.sizes?.forEach((size) => {
            if (size.display === tempSizeDisplay) {
                child.selectedSize = size.value;
            }
        });
        child.productQuantity = tempQuantity;

        child.options.forEach((childOption) => {
            tempOptions.forEach((tempOption) => {
                if (tempOption.optionId == childOption.optionId && tempOption.userValue) {
                    childOption.userValue = tempOption.userValue;
                }
            });
        });

        await this.prepExclusives(child);

        this.selectedProduct = child;
    }
    public backClicked() {
        window.location.assign(this.returnToStoreUrl);
    }
}
