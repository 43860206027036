import { TaxType } from "../products/TaxType";
import { IOrderItem } from "./IOrderItem";
import { IOrderItemColor } from "./IOrderItemColor";
import { IOrderItemOption } from "./IOrderItemOption";
import { IOrderItemSize } from "./IOrderItemSize";

export class OrderItem implements IOrderItem {
    public billFirstName!: string;
    public billLastName!: string;
    public colors!: IOrderItemColor[];
    public description!: string;
    public designName: string | null = null;
    public eventProductId!: number;
    public fundraising!: number;
    public lowerQuantityBounds!: number;
    public name!: string;
    public options!: IOrderItemOption[];
    public optionsPrice!: number;
    public orderItemId!: number;
    public orderNumber!: number;
    public processCost!: number;
    public productName!: string;
    public processName!: string;
    public productPrice!: number;
    public productCost!: number;
    public qty!: number;
    public color!: string;
    public size!: string;
    public selectedTaxType!: TaxType;
    public sellPrice!: number;
    public sizePrice!: number;
    public sizes!: IOrderItemSize[];
    public sizingChart!: string;
    public style!: string;
    public taxRate!: number | undefined;
    public taxType!: TaxType;
    public upperQuantityBounds!: number;
    public selectedQty!: number;
    public imageUrl!: string;
    public colorCode!: string;
    public backorderNote!: string;
    public internalNote!: string;
    public hideOptions!: boolean;
    public hideVendor!: boolean;
    public vendorName!: string;
    public hasPurchaseOrderItem!: boolean;
    public purchaseOrderColor!: string;
    public purchaseOrderSize!: string;
    public hideProductName!: boolean;
    public hideStyle!: boolean;
    public hideIndividualPrices!: boolean;

    protected _selectedColor!: number;
    protected _selectedSize!: number;

    public constructor(orderItem: IOrderItem) {
        Object.assign(this, orderItem);
    }

    public get individualItemTotal() {
        return this.sellPrice + this.sizePrice + this.optionsPrice;
    }

    set selectedColor(color: number) {
        this._selectedColor = color;
        this.updateColor();
    }

    get selectedColor() {
        return this._selectedColor;
    }

    set selectedSize(size: number) {
        this._selectedSize = size;
        this.updateSize();
    }

    get selectedSize() {
        return this._selectedSize;
    }

    public updateColor() {
        for (const color of this.colors) {
            if (color.colorId !== this.selectedColor) {
                continue;
            }
            this.imageUrl = color.image1;
            this.color = color.colorName;
            this.colorCode = color.colorCode;
        }
    }

    public updateSize() {
        for (const size of this.sizes) {
            if (size.sizeId !== this.selectedSize) {
                continue;
            }
            this.size = size.sizeName;
        }
    }
}
