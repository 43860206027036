






























































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import "@mdi/font/css/materialdesignicons.css";
import IDealerDto from "@/chipply/IDealerDto";
import { Prop } from "vue-property-decorator";
import IDealerCategoriesModel from "@/chipply/IDealerCategoriesModel";
import { Utils } from "../../../common";
import LayoutLogo from "@/components/design-elements/LayoutLogo.vue";

@Component({
    components: { LayoutLogo },
})
export default class LandingLayout extends Vue {
    @Prop({
        type: Object,
    })
    public dealerBranding!: IDealerDto | null;

    @Prop({
        type: Object,
    })
    public category!: IDealerCategoriesModel | null;

    @Prop({
        type: Object,
    })
    public homeCategory!: IDealerCategoriesModel | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    public showCategories!: boolean;

    public cartQuantity: number | null = null;
    public drawer = false;
    public isNavigationButtonVisible = false;

    public $refs!: {
        appbar: HTMLElement;
    };

    public openDrawer() {
        this.drawer = !this.drawer;
    }

    private allCategoriesHidden() {
        const unhidden = this.homeCategory!.subCategories.filter((i) => !i.categoryBranding.hideCategory);
        return unhidden && unhidden.length == 0;
    }

    public formatPhone(phone: string) {
        return Utils.formatPhone(phone);
    }

    private isDealerColorLight(backgroundColor: string) {
        let color = +("0x" + backgroundColor.slice(1).replace(/./g, "$&$&"));

        let r = (color >> 16) & 255;
        let g = (color >> 8) & 255;
        let b = color & 255;

        let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

        if (hsp > 160) {
            return "#333333";
        } else {
            return "#FFFFFF";
        }
    }
    private getHeaderColor() {
        if (this.category && this.category.categoryBranding && this.category.categoryBranding.headerColor) {
            return this.category.categoryBranding.headerColor;
        } else {
            return this.dealerBranding?.dealerPrimaryColor;
        }
    }
    private getHeaderBoxShadow() {
        let primaryColor = "";
        if (this.category && this.category.categoryBranding && this.category.categoryBranding.headerColor) {
            primaryColor = this.category.categoryBranding.headerColor;
        } else {
            primaryColor = this.dealerBranding?.dealerPrimaryColor ? this.dealerBranding.dealerPrimaryColor : "#FFFFFF";
        }
        if (primaryColor === "#FFFFFF") {
            return "1px 1px lightgrey";
        } else {
            return "0px 0px";
        }
    }
}
