import ICartItem from "../ICartItem";
import CartItemAttributeViewModel from "./CartItemAttributeViewModel";
import ICartItemColor from "../ICartItemColor";

export default class CartItemViewModel {
    itemName = "";
    itemColorId = 0;
    itemQuantity = 0;
    itemPrice = "0";
    itemTotal = 0;
    itemImageUrl = "";
    eventProductId = 0;
    itemSize = "";
    productMax = 0;
    maxQuantity = 0;
    minQuantity = 0;
    quantityArray: Array<string> = [];
    cartItemId = 0;
    displayQuantity = 0;
    packageId = 0;
    packageName = "";
    packageNum = 0;
    attributes: Array<CartItemAttributeViewModel> = [];
    itemColors: Array<ICartItemColor> = [];
    designIds: Array<number> = [];
    layoutName = "";
    overridePersonalizations = false;
    errorMessage = "";
    colorSizeQuantity = -1;
    itemSizeId = 0;
    hideVendor = false;
    hideStyle = false;
    hideProductName = false;
    hideIndividualPrices = false;

    public constructor(model: ICartItem) {
        this.itemName = model.itemName;
        this.itemColorId = model.itemColorId;
        this.itemQuantity = model.itemQuantity;
        this.itemPrice = model.itemPrice;
        this.itemTotal = model.itemTotal;
        this.itemImageUrl = model.itemImageUrl;
        this.eventProductId = model.eventProductId;
        this.itemSize = model.itemSize;
        this.productMax = model.productMax;
        this.maxQuantity = model.maxQuantity;
        this.minQuantity = model.minQuantity;
        this.quantityArray = model.quantityArray;
        this.cartItemId = model.cartItemId;
        this.displayQuantity = model.displayQuantity;
        this.packageId = model.packageId;
        this.packageName = model.packageName;
        this.packageNum = model.packageNum;
        this.errorMessage = model.errorMessage || "";
        this.designIds = model.designIds;
        this.itemSizeId = model.itemSizeId;
        this.hideVendor = model.hideVendor;
        this.hideStyle = model.hideStyle;
        this.hideProductName = model.hideProductName;
        this.hideIndividualPrices = model.hideIndividualPrices;

        model.attributes.forEach((attr) => {
            this.attributes.push(new CartItemAttributeViewModel(attr));
        });

        this.itemColors = model.itemColors;
        this.layoutName = model.layoutName;
        this.overridePersonalizations = model.overridePersonalizations;
        this.colorSizeQuantity = model.colorSizeQuantity;
    }
}
