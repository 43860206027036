






























































































import IProductDto from "@/chipply/i-product-dto";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import IPackageDto from "@/chipply/i-package-dto";
import { Utils } from "chipply-common";
import PackageDesignFlag from "./PackageDesignFlag.vue";
import { ZeroSellPriceDisplay } from "chipply-common";
import IndividualDisplayCard from "@/components/IndividualDisplayCard.vue";
import ProductDisplayCard from "@/components/ProductDisplayCard.vue";
import ProductImageSlider from "@/components/design-elements/ProductImageSlider.vue";

@Component({
    components: {
        ProductImageSlider,
        ProductDisplayCard,
        IndividualDisplayCard,
        PackageDesignFlag,
    },
})
export default class StorePackage extends Vue {
    public Utils = Utils;

    @Prop({
        default: "",
        type: String,
    })
    public foregroundColor!: string;

    @Prop({
        default: "",
        type: String,
    })
    public backgroundColor!: string;

    @Prop({
        default: "",
        type: String,
    })
    public eventID!: string;

    @Prop()
    public packageDto!: IPackageDto;

    public imagesShown() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return 5;
        } else {
            return 3;
        }
    }

    public buttonWidth() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return 215;
        } else {
            return 100;
        }
    }

    private priceDisplay() {
        let displayLabel = Utils.toCurrency(this.packageDto.price);

        const allCustom = this.packageDto.products.products.every(
            (product) => product.zeroSellPriceDisplay === ZeroSellPriceDisplay.CustomLabel
        );
        const allDoNotDisplay = this.packageDto.products.products.every(
            (product) => product.zeroSellPriceDisplay === ZeroSellPriceDisplay.DoNotDisplay
        );
        const allDisplayAsZero = this.packageDto.products.products.every(
            (product) => product.zeroSellPriceDisplay === ZeroSellPriceDisplay.DisplayAsZero
        );

        const packageHasProducts = this.packageDto.products.products.length > 0;
        if (allCustom && packageHasProducts) {
            return this.packageDto.products.products[0].zeroSellPriceLabel;
        } else if (allDoNotDisplay && packageHasProducts) {
            return this.packageDto.products.products.every((product) => product.basePrice == 0) ? "" : displayLabel;
        } else if (allDisplayAsZero && packageHasProducts) {
            return this.packageDto.products.products.every((product) => product.basePrice == 0)
                ? "$0.00"
                : displayLabel;
        } else {
            return displayLabel;
        }
    }

    public getProductName(product: IProductDto) {
        let productDisplay = "";
        if (!product.hideVendor) {
            productDisplay += product.vendorName;
        }
        if (!product.hideStyle) {
            if (productDisplay.length > 0) {
                productDisplay += " - ";
            }
            productDisplay += product.sku;
        }

        if (!product.hideProductName) {
            if (productDisplay.length > 0) {
                productDisplay += " - ";
            }

            productDisplay += product.productName;
        }

        return productDisplay;
    }
}
